/**
 * Created by km arti on 18/07/23.
 */

export const environment = {
  production: false, 
  

  ///////dev///////////
      CLIENT_ID: '8d6897d6-6503-4f68-9066-0e96aa702728',

  //////prod/////////////
    // CLIENT_ID: 'a07b2158-faac-41cf-aa44-a14d37209aae',
  TENANT_ID: '421e9584-87ff-424f-95b8-1bf46b70db99'

};

